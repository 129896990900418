.footer {
  background-color: #1a293c;
  color: white;
  padding: 30px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.footer-section h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.footer-section p {
  font-size: 0.9rem;
  line-height: 1.5;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  margin-right: 10px;
  transition: 0.3s;
}

.social-icons a:hover {
  color: #4b73a8;
}

.write-to-us {
  margin-top: 20px;
}

.write-to-us h3 {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.email-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s;
}

.email-link svg {
  margin-right: 5px;
  font-size: 1.2rem;
}

.email-link:hover {
  color: #f4a261;
}
